import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimeInput as MantineTimeInput } from "@mantine/dates";
import clsx from "clsx";
import { useRef } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import "./timeInput.scss";

type TimeInputProps = {
    placeholder?: string;
    disabled?: boolean;

    min?: TimeInputValue;
    max?: TimeInputValue;

    value: TimeInputValue | undefined;
    onValueChange: (value: TimeInputValue | undefined) => void;

    error?: SingleValidationError;
    className?: string;
};

export function TimeInput(props: TimeInputProps) {
    const { disabled: controlledDisabled, placeholder = "Zeit" } = props;

    const { disabled: uncontrolledDisabled, state } = useFormControlContext();

    const ref = useRef<HTMLInputElement>(null);

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        if (value === "") {
            props.onValueChange(undefined);
            return;
        }

        const [hoursRaw, minutesRaw] = value.split(":");

        let hours = parseInt(hoursRaw);
        let minutes = parseInt(minutesRaw);

        if (Number.isNaN(hours) && Number.isNaN(minutes)) {
            props.onValueChange(undefined);
            return;
        }

        if (Number.isNaN(hours)) {
            hours = 0;
        }
        if (Number.isNaN(minutes)) {
            minutes = 0;
        }

        props.onValueChange({ hours, minutes });
    };

    return (
        <MantineTimeInput
            ref={ref}
            unstyled
            withAsterisk={false}
            placeholder={placeholder}
            disabled={controlledDisabled || uncontrolledDisabled}
            value={convertTimeValueToString(props.value || undefined)}
            minTime={convertTimeValueToString(props.min || undefined)}
            maxTime={convertTimeValueToString(props.max || undefined)}
            onChange={handleValueChange}
            rightSection={
                <FontAwesomeIcon
                    icon={faClock}
                    onClick={() => {
                        ref.current?.showPicker();
                    }}
                />
            }
            className={clsx("time-input", props.className, {
                "time-input--state-normal": state === "normal",
                "time-input--state-warning": state === "warning",
                "time-input--state-error": state === "error" || props.error,
            })}
            classNames={{
                wrapper: "time-input__wrapper",
                input: "time-input__input",
                section: "time-input__right-section",
            }}
        />
    );
}

export type TimeInputValue = {
    hours: number;
    minutes: number;
};

function convertTimeValueToString(value: TimeInputValue | undefined): string {
    if (!value) {
        return "";
    }

    return `${value.hours.toString().padStart(2, "0")}:${value.minutes.toString().padStart(2, "0")}`;
}
